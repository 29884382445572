import Image from 'next/image';
import Link from 'next/link';
import { Button, ButtonLink, createComponent, IntrinsicProps } from 'react-commons';

import style from './index.module.scss';
import { CaptureClickEvent, CaptureClickEventContext } from '@/lib/util/captureClickEvent';
import { useContext } from 'react';

interface ArchivesUpsellTileProps extends IntrinsicProps {
  eventLocation: string
}

export default
createComponent<ArchivesUpsellTileProps>('ArchivesUpsellTile', { style }, function ArchivesUpsellTile ({ className }, props) {
  const captureClickEventCtx = useContext(CaptureClickEventContext);

  return (
    <CaptureClickEvent
      action='upsell'
      location={props.eventLocation}
      properties={() => ({
        tag_name: 'upsell_archive_teaser',
        title: captureClickEventCtx.settings.gameTitle ?? '',
        id: captureClickEventCtx.settings.gameId ?? '',
      })}
      href='/unlimited'
    >
      <div className={className}>
        <figure>
          <Image 
            src={'/images/archiveUpsellImage.png'} 
            alt={'Theres years worth of content to play'}
            layout='fill'
            aria-hidden
          />
        </figure>
        <section>
          <h3>There's years worth of content to play</h3>
          <p>With a subscription you can gain access to the entire catalog of daily game archives. Ready to play whenever you are!</p>
          <ButtonLink 
            href='/unlimited'
            rounded medium
            className='upsell_archive_teaser'
          >
            Learn More
          </ButtonLink>
        </section>
      </div>
    </CaptureClickEvent>
  );
});
 