import { Button, Buttons, createComponent, If, IntrinsicProps } from 'react-commons';

interface DailyShowMoreButtonProps extends IntrinsicProps {
  isLoading: boolean
  hasReachedEnd: boolean
  showMore: () => void
}

export default createComponent<DailyShowMoreButtonProps>('DailyShowMoreButton', {}, function DailyShowMoreButton ({}, props) {
  return (
    <Buttons>
      {
        If(props.hasReachedEnd, () => (
          <Button rounded medium disabled secondary>End of Results</Button>
        ))
          .Else(() => (
            <Button rounded secondary medium disabled={props.isLoading} onClick={props.showMore}>
              Show More
            </Button>
          ))
          .EndIf()
      }
    </Buttons>
  );
});
