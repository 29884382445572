import { IntrinsicProps, createComponent, If, Flex, For, Title, Block } from 'react-commons';

import { ExtendedGameData } from '@/lib/drupal/models/Games';
import { TaxonomyStub } from '@/lib/drupal/models/Taxonomies';
import { useEffectAfterFirstRender } from '@/lib/hooks/useEffectAfterFirstRender';
import { GamesByTaxonomyCriteria, SortBy, useGamesByTaxonomy } from '@/lib/hooks/useGamesByTaxonomy';
import { DailyGameTileContainer } from '../DailyGameTileContainer';
import { TaxonomyGridDailyGameTile } from '../DailyGameTile';
import DailyShowMoreButton from '../DailyShowMoreButton';

import style from './index.module.scss';

interface DailyGameGenreGridProps extends IntrinsicProps {
  genre?: TaxonomyStub,
  initialGames: ExtendedGameData[]
  initialSortBy?: SortBy
  fetchMoreGames?: GamesByTaxonomyCriteria['method']
  hideToolbar?: boolean
  isDownloadGame?: boolean
  fetchOnSSR?: boolean
}

export default createComponent<DailyGameGenreGridProps>('DailyGameGenreGrid', { style }, function DailyGameGenreGrid ({ slots, className }, props) {
  const placeholderFetchMoreGames = () => { 
    return Promise.resolve(props.initialGames);
  };

  const { 
    games,
    isLoading,
    hasReachedEnd,
    showMoreGames,
    sortBy,
    setSortBy,
  } = useGamesByTaxonomy({
    genre: props.genre || { uid: '', name: '', href: '' },
    initialGames: props.initialGames,
    initialSortBy: props.initialSortBy || 'all',
    fetchMoreGames: props.fetchMoreGames || placeholderFetchMoreGames,
    fetchOnSSR: props.fetchOnSSR
  });

  useEffectAfterFirstRender(() => {
    setSortBy(props.initialSortBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.genre ]);
  
  return (
    <div className={className}>
      {
        If(slots?.title, () => (
          <Title h2 size5 className='--textCenter'>
            {slots.title}
          </Title>
        )).EndIf()
      }

      <Block>
        {
          If(games.length === 0, () => (
            <Flex alignCenter justifyCenter className='--padMd2'>
              <div><strong>There's nothing here...</strong></div>
            </Flex>
          )).Else(() => (
            <DailyGameTileContainer>
              {
                For(games, (game) => (
                  <TaxonomyGridDailyGameTile 
                    game={game} 
                    isDownloadGame={props.isDownloadGame}
                  />
                ))
              }
            </DailyGameTileContainer>
          )).EndIf()
        }
      </Block>

      {
        If(props.fetchMoreGames, () => (
          <Block>
            <Flex justifyCenter>
              <DailyShowMoreButton
                isLoading={isLoading}
                hasReachedEnd={hasReachedEnd}
                showMore={showMoreGames}
              >
              </DailyShowMoreButton>
            </Flex>
          </Block>
        )).EndIf()
      }
    </div>
  );
});
