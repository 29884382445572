import { ButtonLink, createComponent, If, IntrinsicProps, Slot } from 'react-commons';

import { ExtendedGameData, FullGameData } from '@/lib/drupal/models/Games';
import PerDevice from '@/components/PerDevice';
import { GridUpsellPod } from '@/components/dailyUpsellPods/GridUpsellPod';
import Link from 'next/link';
import { useContext, useEffect, useState } from 'react';
import HomeModel from '@/lib/drupal/models/Home';
import { CaptureClickEvent, CaptureClickEventContext } from '@/lib/util/captureClickEvent';

import style from './index.module.scss';

interface DailyGameUpsellTileProps  extends IntrinsicProps {
  priority?: boolean
  game: FullGameData
  isPremiumUser?: boolean
}

export const DailyGameUpsellTile =
createComponent<DailyGameUpsellTileProps>('DailyGameUpsellTile', { style }, function DailyArchiveUpsellTile ({ className }, props) {

  const [ dailyGames, setDailyGames ] = useState<ExtendedGameData[]>([]);

  useEffect(() => {
    (async () => {
      const games = await HomeModel.getFeaturedDailyGames();

      setDailyGames(games.slice(0,6));
    })();
  }, []);

  const desktopItems = dailyGames.map((dailyGame, i) => {
    return (
      <Link href={dailyGame.href} key={i} >
        <a className='DailyGameUpsellTile__DailyGameLink'>
          <img src={dailyGame.mobileIcon} alt={dailyGame.title} />
        </a>
      </Link>
    );
    
  });

  const captureClickEventCtx = useContext(CaptureClickEventContext);

  if(dailyGames.length === 0) {
    return <></>;
  }

  return (
    <CaptureClickEvent
      action='upsell'
      location='mid_page_upsell'
      properties={() => ({
        tag_name: 'upsell_dailygame_teaser',
        title: captureClickEventCtx.settings.gameTitle ?? '',
        id: captureClickEventCtx.settings.gameId ?? '',
      })}
      href='/unlimited'
    >
      <GridUpsellPod 
        backgroundColor={props.game.highlightColor} 
        rightText='All of our Daily Games come with an archive full of puzzles!'
        items={desktopItems}
        mobileItem={desktopItems[ 1 ]} 
      >
        <Slot name='leftText'>
          <PerDevice useDisplay>
            <Slot name='mobile'>
              <h4 className='--marMd0__b'><strong>{props.game.title}</strong></h4>
              <p>{props.game.shortDescription}</p>
            </Slot>
            <Slot name='desktop'>
              <p>
                With {props.isPremiumUser ? 'your' : 'a'} subscription you {props.isPremiumUser ? 'gain' : 'can gain'} access to the entire catalog of daily game archives. From Daily Jigsaw to Daily Type, we've got you covered.
              </p>
            </Slot>
          </PerDevice>
        </Slot>
        <Slot name='leftButton'>
          <PerDevice useDisplay>
            <Slot name='mobile'>
              <ButtonLink href={`${props.game.href}`} rounded fullWidth medium className='--alignSelfFlexStart'>Play</ButtonLink>
            </Slot>
            <Slot name='desktop'>
              {
                If(props.isPremiumUser, () => (
                  <ButtonLink href={`${props.game.href}/archive`} rounded medium className='--alignSelfFlexStart'>View Archive</ButtonLink>
                )).Else(() => (
                  <ButtonLink 
                    href='/unlimited' 
                    rounded medium 
                    className='upsell_dailygame_teaser --alignSelfFlexStart'
                  >
                    Subscribe
                  </ButtonLink>
                )).EndIf()
              }
            </Slot>
          </PerDevice>
        </Slot>
        <Slot name='rightButton'>
          {
            If(props.isPremiumUser, () => (
              <ButtonLink href={`${props.game.href}/archive`} rounded small>View Archive</ButtonLink>
            )).Else(() => (
              <ButtonLink 
                rounded small 
                href='/unlimited'
                className='upsell_dailygame_teaser'
              >
                Subscribe
              </ButtonLink>
            )).EndIf()
          }
        </Slot>
      </GridUpsellPod>
    </CaptureClickEvent>
  );
});
