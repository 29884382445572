import { useState } from 'react';
import { GameData } from '../drupal/models/Games';
import { useGameList } from './useGameList';
import { TaxonomyStub } from '@/lib/drupal/models/Taxonomies';

export async function fetchGamesByTaxonomyTerm (criteria: GamesByTaxonomyCriteria, page = 0) {
  const limit = NUM_GAMES_PER_SEARCH;
  const offset = NUM_GAMES_PER_SEARCH * page;

  try {
    const games = await criteria.method?.(limit, offset, criteria);
    return games;
  } catch (error) {
    throw error;
  }
}

export const NUM_GAMES_PER_SEARCH = 12;
export const MIN_QUERY_LENGTH = 3;

export const SORT_BY = {
  all: 'All Games',
  token: 'Token Games',
  trophy: 'Trophy Games',
};

export type SortBy = keyof typeof SORT_BY

export interface UseGamesByTaxonomyOptions {
  genre: TaxonomyStub
  initialGames: GameData[]
  initialSortBy: SortBy
  fetchMoreGames: GamesByTaxonomyCriteria['method']
  fetchOnSSR?: boolean
}

export interface GamesByTaxonomyCriteria {
  genre: TaxonomyStub
  sortBy: SortBy
  method?: (limit: number, offset: number, criteria: Omit<GamesByTaxonomyCriteria, 'method'>) => Promise<GameData[]>
}

export function useGamesByTaxonomy (options: UseGamesByTaxonomyOptions) {
  const [ sortBy, setSortByDispatch ] = useState<SortBy>(options.initialSortBy);

  // Setup game list
  const { 
    games,
    isLoading,
    hasReachedEnd,
    showMoreGames,
    newSearch,
  } = useGameList<GamesByTaxonomyCriteria>({
    initialGames: options.initialGames,
    numGamesPerRequest: NUM_GAMES_PER_SEARCH,
    fetchOnSSR: options.fetchOnSSR,
    criteria: {
      genre: options.genre,
      sortBy,
      method: options.fetchMoreGames,
    },
    fetchMoreGames: async (criteria, page) => {
      return await fetchGamesByTaxonomyTerm(criteria, page);
    }
  });

  // Methods
  const setSortBy = (nextSort: SortBy) => {
    newSearch(() => {
      setSortByDispatch(nextSort);
    });
  };

  return {
    games,
    isLoading,
    hasReachedEnd,
    showMoreGames,
    sortBy: sortBy,
    setSortBy,
    newSearch,
  };
}
