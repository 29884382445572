import { useState, useEffect, useRef } from 'react';
import { useLoadingState } from './useLoadingState';

interface UseGamesListOptions <Criteria> {
  initialGames?: any[]
  fetchOnFirstRender?: boolean
  fetchOnSSR?: boolean
  numGamesPerRequest: number
  criteria: Criteria
  isCriteriaValid?: (criteria: Criteria) => boolean
  fetchMoreGames: (criteria: Criteria, page: number) => Promise<any[]>
  replaceGamesOnSearch?: boolean
}

export function useGameList <Criteria> (options: UseGamesListOptions<Criteria>) {
  const initialGames = options.initialGames || [];
  const [ games, setGames ] = useState(initialGames);

  // start on the first (next) page, since we should have 1 page of results to begin with
  const [ page, setPage ] = useState(
    (options.fetchOnFirstRender || options.replaceGamesOnSearch || options.fetchOnSSR)
      ? 0 
      : 1
  );

  const [ isLoading, startLoading, finishLoading ] = useLoadingState();
  const [ hasReachedEnd, setHasReachedEnd ] = useState(
    options.fetchOnFirstRender 
      ? false 
      : initialGames.length < options.numGamesPerRequest
  );

  const lastPage = useRef(page);
  const lastCriteria = useRef(JSON.stringify(options.criteria));
  const initialRenderRef = useRef(true);

  // When criteria or page number changes, do a new search
  useEffect(() => {
    if (!options.fetchOnFirstRender && initialRenderRef.current) {
      initialRenderRef.current = false;
      return;
    }

    if (
      lastPage.current === page &&
      lastCriteria.current === JSON.stringify(options.criteria)
    ) {
      return;
    }

    lastPage.current = page;
    lastCriteria.current = JSON.stringify(options.criteria);

    if (isLoading) {
      return;
    }

    const doSearch = async () => {
      if (options.isCriteriaValid && !options.isCriteriaValid(options.criteria)) return;

      startLoading();

      const nextGames = await options.fetchMoreGames(options.criteria, page);
      if (nextGames.length < options.numGamesPerRequest) setHasReachedEnd(true);

      if (page === 0) {
        setGames(() => nextGames); // fresh search, replace games
      } else {
        if (options.replaceGamesOnSearch) setGames(() => nextGames);
        else setGames((curGames) => [ ...curGames, ...nextGames ]); // append to existing games
      }

      finishLoading();
    };
    doSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ options.criteria, page ]);

  // Show more games when button clicked
  const showMoreGames = async () => {
    setPage((curPage) => curPage + 1);
  };

  const setCurrentPage = async (page: number) => {
    setPage(() => page);
  };

  // Reset
  const newSearch = (beforeSearch?: () => void) => {
    if (beforeSearch) beforeSearch();
    setHasReachedEnd(false);
    setPage(0);
  };

  return {
    games,
    page,
    isLoading,
    hasReachedEnd,
    showMoreGames,
    setCurrentPage,
    newSearch,
  };
}
